import React from "react";

const Home = () =>{
    return(<div className="home-card">
    <div>
      <p className="home-title">UNDER CONSTRUCTION</p>
    </div>
  </div>);
}

export default Home;